import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import Img from "gatsby-image"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Header from "../../components/header"
import TwoAndOne from "../../components/twoAndOne"
const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      flyer1: file(relativePath: { eq: "beratung/frauen.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      flyer2: file(relativePath: { eq: "beratung/pix.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Pathologisches Glücksspiel" />
      <Header siteTitle="Beratung" subTitle="Pathologisches Glücksspiel" />
      <TwoAndOne>
        <div className="content">
          <p>
            Glücksspiele sind keine Erfindung der Neuzeit. Seit langem prägen
            Begriffe aus dem Spiel unsere Sprache: „Haus und Hof verspielen“,
            „Sekt oder Selters“, „Hop oder Top“. Skatrunden unter Freunden, die
            Teilnahme an der wöchentlichen Lottoziehung oder an einem
            Gewinnspiel gehören für viele Menschen seit Jahrzehnten zum
            Alltagsleben. Sie bringen Freude und positive Erwartungen und sind
            für viele Menschen völlig unproblematisch.
          </p>
          <p>
            Die Entwicklung des Geld- und Glücksspielmarktes hingegen ist eine
            hochmoderne und hochtechnologisierte Entwicklung. Die Ansiedlung von
            Spielhallen scheint auch vor der kleinsten Kommune nicht halt zu
            machen. In der Pommesbude oder der Gasstätte um die Ecke stehen
            regelmäßig Geräte, die auf den Einwurf von Wechselgeld warten.
            Spielbanken werben und suchen die Nähe zum Besucher in den
            Innenstädten. Dazu kommen die Angebote der Lotterien, der Buchmacher
            und die Vermarktung über das Internet, das die Spielmöglichkeiten
            sogar bis in das Wohnzimmer eines jeden Bürgers transportiert.
          </p>
          <p>
            In Hochglanz präsentiert, versprechen die Glücksspiele einen hohen
            Unterhaltungswert und die Chance auf das ganz große (finanzielle)
            Glück. Nicht alle Menschen können mit diesen Versprechungen umgehen.
            Aus einer gelegentlichen Teilnahme wird ein regelmäßiger Konsum und
            immer häufiger entwickelt sich daraus eine Abhängigkeit.
          </p>
          <p>
            Glücksspielsucht ist eine anerkannte Krankheit. Sie wird für die
            Betroffenen zum zentralen Lebensmittelpunkt. Je mehr jedoch das
            Spielen in den Vordergrund tritt, desto deutlicher zeigen sich die
            Folgen der Suchterkrankung:
          </p>
          <ul>
            <li>
              Soziale Kontakte werden vernachlässigt, Freunde brechen weg{" "}
            </li>
            <li>Familiäre Konflikte häufen sich, Beziehungen zerbrechen</li>
            <li>Finanzielle Sorgen steigen, die Verschuldung nimmt zu</li>
            <li>
              Gedanken an das Spielen und die Geldbeschaffung weiten sich aus
            </li>
            <li>
              Das eigene Selbstwertgefühl sinkt, Scham und Schuldgefühle wachsen
            </li>
          </ul>
          Bereits frühzeitig lassen sich Warnhinweise beobachten. Gefährdet ist,
          wer:
          <ul>
            <li>Mehr Geld verspielt, als ihm zur Verfügung steht </li>
            <li>Spielt, um unangenehmen Situationen „entfliehen“ zu können </li>
            <li>
              Sein Spielverhalten verheimlicht, Verluste kleinredet und Gewinne
              überhöht
            </li>
            <li>
              Eigene Vorgaben bzgl. Geldeinsatz und Spielzeit nicht einhalten
              kann
            </li>
            <li>Seinen Vorsatz nicht zu spielen nicht einhalten kann</li>
            <li> Weiter spielt, obwohl das Spielen zu Nachteilen führt </li>
            <li> Geld beschafft, um weiter spielen zu können</li>
          </ul>
          <div className="notification is-primary">
            Sollten Sie sich oder einen Angehörigen in dieser Beschreibung
            wiederfinden oder hat der Selbsttest eine entsprechende Auswertung
            ergeben, sollten Sie sich in einem persönlichen Gespräch beraten
            lassen
          </div>
        </div>
        <div className="content">
          <div className="notification is-warning">
            <h4 className="title">Selbsttest</h4>
            <p>
              Check dein Spiel bietet Betroffenen, Angehörigen und
              Interessierten Informationen und Beratung rund um das Themenfeld
              Glücksspiel und Glücksspielsucht.
            </p>
            <a
              href="https://www.check-dein-spiel.de"
              target="_blank"
              className="button"
            >
              <span className="icon is-small">
                <i className="fas fa-external-link-alt"></i>
              </span>
              <span>Zum Selbsttest</span>
            </a>
          </div>
          <div className="notification is-primary">
            <h4 className="title">
              Informationsbroschüren für pathologische Glücksspieler
            </h4>
            <div className="columns">
              <div className="column is-one-third">
                <Img fluid={data.flyer1.childImageSharp.fluid} />
              </div>
              <div className="column">
                <h5 className="title">Frauen spielen anders</h5>
                <h6 className="subtitle">Broschüre</h6>
                <p>
                  Herausgeber:
                  <br />
                  Landesfachstelle Glücksspielsucht NRW
                  <br />
                  <a
                    target='_blank'
                    href="/files/frauen-spielen-anders.pdf"
                  >
                    <span className="icon is-small">
                      <i className="fas fa-download"></i>
                    </span>
                    <span> Herunterladen</span>
                  </a>
                </p>
              </div>
            </div>
            <hr />
            <div className="columns">
              <div className="column is-one-third">
                <Img fluid={data.flyer2.childImageSharp.fluid} />
              </div>
              <div className="column">
                <h5 className="title">Glücksspielsucht</h5>
                <h6 className="subtitle">Informationen zur Glücksspielsucht</h6>
                <p>
                  Herausgeber: BZGA
                  <br />
                  <a

                    target='_blank'
                    href="/files/wenn-spiel-zur-sucht-wird.pdf"
                  >
                    <span className="icon is-small">
                      <i className="fas fa-download"></i>
                    </span>
                    <span> Herunterladen</span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </TwoAndOne>
    </Layout>
  )
}

export default IndexPage
